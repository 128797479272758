<template>
  <OverviewComponent company_type="counterparty"/>
  <b-row class="mb-4">
    <b-col :xl="4" :xxl="3">
      <TotalOrdersStatus company_type="counterparty"/>
      <CounterpartyEditCard company_type="counterparty"/>
    </b-col>
    <b-col :xl="8" :xxl="9">
      <OrdersList company_type="counterparty"/>
    </b-col>
  </b-row>
</template>

<script>
import OverviewComponent from "@/views/pages/accounts/counterparties/components/OverviewComponent.vue";
import TotalOrdersStatus from "@/views/pages/accounts/counterparties/components/TotalOrdersStatus.vue";
import CounterpartyEditCard from "@/views/pages/accounts/counterparties/components/CounterpartyEditCard.vue";
import OrdersList from "@/views/pages/accounts/counterparties/components/OrdersList.vue";

export default {
  name: "client_profile_overview",
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  components: {
    OverviewComponent,
    TotalOrdersStatus,
    CounterpartyEditCard,
    OrdersList
  },
}
</script>

<style scoped>

</style>